<template>
	<v-card>
		<v-card-title>
			<span class="headline">Aanpassen/Toevoegen Bedrijf</span>
		</v-card-title>

		<v-card-text v-if="editedItem">
			<v-container>
				<v-row>
          	<v-col cols="12">
						<v-text-field
								outlined
								v-model="editedItem.omniboxxId"
								label="Omniboxx Id"
								required
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-text-field
								outlined
								v-model="editedItem.name"
								label="Naam"
								required
						></v-text-field>
					</v-col>

					<v-col cols="12">
						<v-text-field
								outlined
								v-model="editedItem.bankAccount"
								label="Rekeningnummer"
								required
						></v-text-field>
					</v-col>
					<v-col cols="12"  >
						<v-text-field
								outlined
								v-model="editedItem.accountName"
								label="naam rekeninghouder"
								required
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<v-text-field
								outlined
								v-model="editedItem.key"
								label="API key Mollie (enkel aanpassen indien je weet wat je doet)"
								required
						></v-text-field>
					</v-col>
					<v-col cols="12" >
						<v-text-field
								outlined
								v-model="editedItem.eCurringKey"
								label="API key eCurring by Mollie (enkel aanpassen indien je weet wat je doet)"
								required
						></v-text-field>
					</v-col>
					<v-col cols="12" >
						<v-text-field
								outlined
								v-model="editedItem.twikeyApiKey"
								label="Twikey API ley (enkel aanpassen indien je weet wat je doet)"

						></v-text-field>
					</v-col>
					<v-col cols="12" >
						<v-text-field
								outlined
								v-model="editedItem.twikeyCtId"
								label="Twikey Profile Template ID (enkel aanpassen indien je weet wat je doet)"

						></v-text-field>
					</v-col>
					<v-col cols="12" >
						<v-text-field
								outlined
								v-model="editedItem.twikeyBaseApiUrl"
								label="Twikey API Base URL (enkel aanpassen indien je weet wat je doet)"

						></v-text-field>
					</v-col>
					<v-col cols="12" >
						<v-text-field
								outlined
								v-model="editedItem.twikeyPlan"
								label="Twikey Plan (enkel aanpassen indien je weet wat je doet)"

						></v-text-field>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>

		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
					color="blue darken-1"
					text
					@click="$emit('close')"
			>
				Cancel
			</v-btn>
			<v-btn
					color="blue darken-1"
					text
					@click="$emit('save', editedItem)"
			>
				Save
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	export default {
		name: "company-edit",
		props: {
			editedItem: Object
		},
		watch: {
			dialog(val) {
				val || this.close()
			},
		},
	}
</script>

<style scoped>
	.container {
		margin-left: 0 !important;
	}

</style>
